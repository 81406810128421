import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/www/src/templates/mdx-page-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Link to="/forms" mdxType="Link">Forms</Link>
    <h1 {...{
      "id": "tai-chi-kung-10-movement-form"
    }}>{`Tai Chi Kung 10 Movement Form`}</h1>
    <p>{`This form was created by Master Yang Jun for the International Tai Chi Symposium in Louiville, KY in 2014.`}</p>
    <h2 {...{
      "id": "sequence"
    }}>{`Sequence`}</h2>
    <ol>
      <li parentName="ol">{`Opening and Single Whip`}</li>
      <li parentName="ol">{`Fist Under Elbow`}</li>
      <li parentName="ol">{`Repel Monkey (x2)`}</li>
      <li parentName="ol">{`Brush Knee and Push Left`}</li>
      <li parentName="ol">{`Part the Wild Horse’s Mane (x2)`}</li>
      <li parentName="ol">{`Fair Lady Works Shuttles (x2)`}</li>
      <li parentName="ol">{`Turn Body, Left Heel Kick`}</li>
      <li parentName="ol">{`Parry, Block, Punch`}</li>
      <li parentName="ol">{`Grasp the Bird’s Tail`}</li>
      <li parentName="ol">{`Cross Arms and Closing`}</li>
    </ol>
    <h2 {...{
      "id": "video"
    }}>{`Video`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=h2S_jpLM7os&t=15"
      }}><iframe parentName="a" {...{
          "width": 560,
          "height": 315,
          "src": "https://www.youtube-nocookie.com/embed/h2S_jpLM7os?rel=0&start=15",
          "frameBorder": "0",
          "allow": "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
          "allowFullScreen": true
        }}></iframe></a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      